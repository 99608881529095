<template>
  <section v-if="slice"
           :class="`homepage-hero-with-slider ${slice.primary.display_200_year_anniversary_branding ? 'homepage-hero-with-slider--200': ''} ${logoPositionClass}`">
    <b-container>
      <b-row>
        <b-col md="12" lg="6" xl="5">
          <div class="hero-top-box">
            <h1>SPOTLIGHT</h1>
            <p>{{ slice.primary.title1 }}</p>
            <p v-if="slice.primary.subline" class="hero-top-box__subline">{{ slice.primary.subline }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <VueSlickCarousel :arrows="true" :dots="true" @init="removeAriaHidden" @afterChange="removeAriaHidden"
                      :accessible="true">
      <section v-for="(item, index) in slice.items" :key="'homepage-hero-with-slider' + index">
        <div class="homepage-hero-with-slider__slider__innerbox">
          <b-container>
            <b-row>
              <b-col sm="12" md="6" lg="6" xl="7">
                <div class="top-box">

                  <h1 v-if="item.title1">{{ item.title1[0].text }}</h1>
                  <p v-if="item.content">{{ item.content }}</p>
                  <b-button v-if="item.button_text" :href="globalMakeLink(item.button_link)" variant="primary"
                            class="btn">{{ item.button_text }}
                  </b-button>
                </div>
                <div class="homepage-hero-with-slider__slider__innerbox__bg-image d-block d-md-none"
                     :style="mobileBanner(item)"/>
                <div class="homepage-hero-with-slider__slider__innerbox__bg-image d-none d-md-block"
                     :style="banner(item)"/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </section>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HeroWithSlideshow",
  components: {VueSlickCarousel},
  props: {
    slice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      options: {
        type: "loop",
        arrows: false,
        dotsClass: "slick-dots",
        rewind: true,
        width: "100%",
        perPage: 1,
        infinite: false,
      },
    };
  },
  computed: {
    logoPositionClass() {
      return this.slice.primary.railway_200_logo_position ? `homepage-hero-with-slider--${this.slice.primary.railway_200_logo_position.toLowerCase().replaceAll(" ", "-")}` : "";
    },
  },
  methods: {
    /**
     * get the banner bg image
     * @param item
     * @param imageSize
     * @returns {{backgroundImage: string}}
     */
    banner(item, imageSize) {
      let backgroundImageUrl = null;
      if (item.image) {
        if (!imageSize) {
          backgroundImageUrl = item.image.url;
        } else {
          if (item.image[imageSize]) {
            backgroundImageUrl = item.image[imageSize].url;
          } else {
            backgroundImageUrl = item.image.url;
          }
        }
      }
      if (backgroundImageUrl) {
        return {backgroundImage: `url('${backgroundImageUrl}')`};
      }
    },
    mobileBanner(item, imageSize) {
      let backgroundImageUrl = null;
      if (item.image) {
        if (!imageSize) {
          backgroundImageUrl = item.mobile_image.url;
        } else {
          if (item.image[imageSize]) {
            backgroundImageUrl = item.mobile_image[imageSize].url;
          } else {
            backgroundImageUrl = item.mobile_image.url;
          }
        }
      }
      if (backgroundImageUrl) {
        return {backgroundImage: `url('${backgroundImageUrl}')`};
      }
    },
  },
  mounted() {
    /** For acessibilty related links , need to disable tabindex from hero slick-arrow elements */
    this.globalDisableFocusForElements("slick-arrow");

    /**
     *  This is to fix an issue with slick slider, as it creates clones of the slideshow , which makes
     *  tabindex buggy as it creates tabindex copied from original clones, we disable the tabindexes on the clone
     */
    this.globalDisableFocusForElements("slick-cloned");

    let x = document.getElementsByClassName("homepage-hero-with-slider__slider__innerbox");
    for (let i = 0; i < x.length; i++) {
      if (i == 4) {
        let contentBox = x[i].childNodes[x[i].childNodes.length - 1];
        contentBox.childNodes[0].setAttribute("tabindex", "-1");
      }
    }

  },
};
</script>

<style lang="scss">
.hero-top-box {
  background-color: #1D7880;
  z-index: 1000;
  position: relative;
  margin-top: 23px;
  padding-top: 20px;
  width: calc(100% - 60px);
  display: none;

  @include respond-below(sm) {
    display: block;
  }

  h1 {
    top: 10px;
    z-index: 10;
    font-family: "DINCondensed";
    color: white;
    font-size: 80px;
    width: 100%;
  }

  p {
    color: white;
    width: 80%;
    text-transform: uppercase;
    font-size: 16px;
  }

  &__subline {
    font-family: 'ArialBold';
  }
}

.homepage-hero-with-slider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #1D7880;

  &--bottom-right {
    &:after {

      @include respond-below(sm) {
        bottom: 0;
      }
    }
  }

  &--top-right {
    &:after {
      @include respond-below(sm) {
        top: 200px;
      }
    }
  }

  &--200 {
    background: {
      image: url("../../../assets/img/200-her-bg-track.svg");
      size: auto 531px;
      repeat: no-repeat;
      position: center;
    }

    @include respond-below(md) {
      background-image: none;
    }

    @media screen and (max-width: 1440px) {
      background-position-x: -1030px;
      background-size: auto 600px;
    }


    // logo
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: calc(50vw - 1504px / 2);
      width: 450px;
      height: 247px;
      background: {
        image: url("../../../assets/img/railway200-logo-white.svg");
        size: contain;
        repeat: no-repeat;
        position: bottom right;
      }

      @include respond-below(lg) {
        right: 0;
        width: 300px;
        height: 250px;
      }

      @include respond-below(sm) {
        right: 0;
        width: 200px;
        height: 150px;
      }
    }
  }

  .top-box {
    //position: absolute;
    //top: 0;
    //left: 0;

    @include respond-above(xl) {
      width: calc(1504px / 2);
    }

    @include respond-above(md) {
      width: calc(900px / 2);
    }


    @include respond-below(sm) {
      width: 100%;
      height: calc(100% - 180px);
    }

    @media screen and (max-width: 1514px) {
      left: 10px
    }

    @media screen and (max-width: 1440px) {
      left: 40px
    }

    @include respond-below(sm) {
      left: 5px;
    }
  }

  &__slider {

    &__innerbox {
      width: 100%;
      margin: 0 auto -10px;
      min-height: 710px;
      height: auto;
      position: relative;
      overflow: hidden;

      .row > * {
        padding: 0;
      }

      @include respond-below(sm) {
        height: auto;
      }

      &__bg-image {
        z-index: 1;
        position: absolute;
        height: 710px;
        width: 100%;
        clip-path: polygon(65.5% 0, 99% 0, 96% 100%, 22% 100%);
        margin: 0 auto;
        bottom: 0;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        // max-width breakpoints from bootstrap
        @include respond-above(xl) {
          max-width: 1504px;
          left: calc(50vw - 1504px / 2);
        }

        @include respond-below(sm) {
          clip-path: none;
          width: 100%;
          height: 100%;
          background-position: center center;

          &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 70%);
          }
        }
      }

      .top-box {
        position: relative;
        z-index: 2;
        padding-top: 163px;

        @include respond-below(sm) {
          position: absolute;
          bottom: 0;
          padding: 150px 16px 0;
        }

        h1 {
          font-size: 3.4375rem;
          line-height: 3.75rem;
          color: #fff;

          @include respond-below(sm) {
            font-size: 3rem;
            line-height: 3rem;
          }
        }

        p {
          color: #fff;
        }


        p {
          letter-spacing: 0.8px;
          padding-right: 40%;

          @include respond-below(lg) {
            padding-right: 0;
          }

          @include respond-below(sm) {
            font-size: 20px;
          }
        }

        @include respond-below(sm) {
          margin-bottom: 20px;
        }
      }

      .content-box {
        position: relative;
        z-index: 2;
        text-align: left;
        color: #fff;
        overflow: hidden;
        max-width: 80%;
        margin-left: auto;

        span {
          transition: .2s ease-in-out;
          padding: 4px 10px 6px;
          box-decoration-break: clone;
          -webkit-box-decoration-break: clone;
        }

        &__title {
          color: #fff;
          margin-bottom: 15px;
          transition: .2s ease-in-out;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        &__text {
          color: #fff;
          margin: 0;
          transition: .1s ease-in-out;
          text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
        }

        @include respond-below(md) {
          max-width: 100%;
        }

        @include respond-below(sm) {
          padding-right: 17%;
          position: absolute;
          bottom: 70px;

          h1 {
            font-size: 40px;
            line-height: 45px;
          }
        }
      }

      @include respond-below(sm) {
        padding-bottom: 0;
        min-height: auto;
        padding-inline: 0;
        height: 664px;
        overflow: hidden;
      }

      &:hover,
      &:focus {
        .content-box {
          h1,
          p {
            span {
              text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
              background-color: black;
            }
          }
        }
      }
    }
  }

  .slick-slider {
    position: relative;

    .slick-dots {
      bottom: 80px;
      left: calc(50vw - 1504px / 2 + 10px);
      text-align: left;

      @media screen and (max-width: 1514px) {
        left: 10px
      }

      @media screen and (max-width: 1440px) {
        left: 40px
      }

      @include respond-below(sm) {
        top: 20px;
        width: 100%;
        left: 0;
        display: flex !important;
        justify-content: center;

      }

      li {
        margin: auto;
        width: 25px;
        height: 14px;

        @include respond-below(sm) {
          margin: 0 1px 10px 1px;
          text-align: center;
        }

        button {
          border: 1px solid #fff;
          background: transparent;
          border-radius: 100%;
          width: 14px;
          height: 14px;
          margin: auto;

          &:before {
            display: none;
          }

          @include respond-below(sm) {
            width: 10px;
            height: 10px;
            margin: auto;
          }
        }

        &.slick-active {
          button {
            background: #fff;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    margin-bottom: 50px;
  }

  .btn {
    height: 48px;
    border-radius: 24px;
    background-color: #F9B000;
    border-color: #F9B000;
    color: black;
    font-family: ArialBold;
    font-size: 18px;
    display: inline-flex;
    margin-top: 19px;
    padding: 0 29px;
    align-items: center;

    &:hover, &:focus {
      background-color: #000;
      border-color: #000;
      color: #F9B000;
    }
  }
}
</style>
